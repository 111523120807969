import {SETTINGS_TOGGLE} from '../actions/settingsActions';
const initialState = {
	toggle:false
};
export default (state = initialState, action) => {

  switch (action.type) {
      case SETTINGS_TOGGLE:
          return {toggle:!state.toggle}  
        
  
      default:
          return state;
  }
}
