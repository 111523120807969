import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter,HashRouter  } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore,combineReducers } from 'redux';
import authReducer from './redux/reducer/authReducer';
import settingsReducer from './redux/reducer/settingsReducer';
const rootReducer = combineReducers({authReducer,settingsReducer});
const store = createStore(rootReducer);
ReactDOM.render(
	<BrowserRouter>
		<Provider store={store}>
			<HashRouter basename="/"  >
				<App />
			</HashRouter>
		
		</Provider>
	</BrowserRouter>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
