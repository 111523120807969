import React,{Component} from 'react'

import { Container, Button, Modal, Table } from 'semantic-ui-react';
import Axios from 'axios';
import {connect} from 'react-redux';
import {Redirect,Link} from 'react-router-dom';
import _ from 'lodash';
class RefferanceList extends Component {
  constructor(props){
    super(props)
    this.state={
      userlist:[],
      open:false
    }
  }
  show = (id) => this.setState({id, open: true })
  close = () => this.setState({ open: false,id:undefined })
  componentDidMount() {
    //let params = new URLSearchParams(location.id);
    const { match: { params } } = this.props;
    this.setState({
        id: params.feedbackid
    }, () => console.log('reportid',this.state.id));
    this.getData();
}
  getData=async()=>{
    const url = 'https://bisorye.herokuapp.com/api/admin/allfoodanalyzer';
    try {
      const result = await Axios.get(url);
      //console.log('result',result.data);
       let userlist = [];
       for (const key in result.data) {
         if (result.data.hasOwnProperty(key)) {
           const element = result.data[key];
          
           userlist.push(element);
         }
       }
       this.setState({userlist});
       console.log('userlist',userlist);
    } catch (error) {
      console.log('error willmount',error)
    }
  }
  onDelete=async ()=>{
    try {
        const result =  await Axios.delete(`https://bisorye.herokuapp.com/api/admin/foodanalyzerdeleted/${this.state.id}`)
        const userlist = _.remove(this.state.userlist,{_id:this.state.id});      
    
        this.setState({open:false,id:undefined})
    
    } catch (error) {
        console.log('error',error)
    }
}
  render() {
    if(!this.props.isLogin) return <Redirect to='/login' />
    return (
      <Container fluid   >
        <h1> Gıda Analiz Başvuruları  </h1>
        <Table celled compact definition unstackable>
      <Table.Header fullWidth>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell>Başvuru Sahibi</Table.HeaderCell>         
          <Table.HeaderCell>İşletme Adı</Table.HeaderCell>     
          <Table.HeaderCell>İşletme Yetkilisi</Table.HeaderCell> 
         
          <Table.HeaderCell>İşletme Mail</Table.HeaderCell>     
          <Table.HeaderCell>İşletme Telefon</Table.HeaderCell>   
          <Table.HeaderCell>İşletme Adresi</Table.HeaderCell>       
        </Table.Row>
      </Table.Header>
  
      <Table.Body>
      {this.state.userlist.map((value,index)=>{
           return(
            <Table.Row key={value._id} >
            <Table.Cell collapsing>
            <Button size='tiny' onClick={()=>this.show(value._id)} >Sil</Button>
            </Table.Cell>
            
            <Table.Cell>{value.reffer}</Table.Cell>
            
            <Table.Cell>{value.name}</Table.Cell>
            <Table.Cell>{value.authorized}</Table.Cell>
            <Table.Cell>{value.mail}</Table.Cell>
            <Table.Cell>{value.phone}</Table.Cell>
            <Table.Cell>{value.adress}</Table.Cell>
         </Table.Row>
           )
         })}
        
  
      </Table.Body>
  
      <Table.Footer fullWidth>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell colSpan='6'>
          
            <Button size='small'>Sil</Button>
            <Button disabled size='small'>
              Hepsini Sil
            </Button>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
    <Modal size='small' open={this.state.open} onClose={this.close}>
          <Modal.Header>Silme İşlemi Onayı</Modal.Header>
          <Modal.Content>
            <p>Silmek istediğinize emin misiniz?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button negative icon='x' labelPosition='right' content='Hayır' onClick={()=>this.setState({open:false,id:undefined})} />
            <Button positive icon='checkmark' labelPosition='right' content='Evet'  onClick={()=>this.onDelete()} /> 
          </Modal.Actions>
        </Modal>
      </Container>
    )
  }
}


const mapStateToProps = state =>{

  return{
    isLogin:state.authReducer.isLogin
  }
}
export default connect(mapStateToProps)(RefferanceList);