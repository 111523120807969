import React, { Component } from 'react'

import { Container, Button, Header, Form } from 'semantic-ui-react';
import Axios from 'axios';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
class AdminRegister extends Component {
    constructor(props) {
        super(props)
        this.state = {          
            fullname: '',
            mail: '',
            password: '',
            btnDisable:false,
            onFinished:false,
            formLoading:true
        }
    }
  
    componentDidMount() {
      this.setState({
          formLoading:false
      })
    }
    
    addAdmin = async () => {
        console.log('admin',this.state);
        this.setState({btnDisable:true,formLoading:true})
        const {fullname,mail,password} = this.state;
        const updateData = {           
            full_name:fullname,
            email:mail,
            password:password
        }
        try {
            const result = await Axios.post(`https://bisorye.herokuapp.com/api/admin/register/`,updateData);
            console.log('result',result.data)
            this.setState({
                onFinished:true,
                formLoading:false
            })
        } catch (error) {
            console.log('resultError',error);
        }
    }
   

    render() {
        if (!this.props.isLogin) return <Redirect to='/login' />
        if(this.state.onFinished) return <Redirect to='/admin/adminlist' />
        return (
            <Container fluid >
                <Header as='h1'>Yönetici Ekle</Header>
                <div style={{ width: 400, marginLeft: 10 }}   >
                    <Form loading={this.state.formLoading} >
                        <Form.Input icon='user'
                            iconPosition='left'
                            label='Ad Soyad'
                            placeholder='Ad-Soyad Giriniz..'
                            onChange={(fullname) => this.setState({ fullname: fullname.target.value })}
                        />
                        <Form.Input icon='mail'
                            iconPosition='left'
                            label='Mail Adresi'
                            placeholder='Mail Adresi Giriniz..'
                            onChange={(mail) => this.setState({ mail: mail.target.value })}

                        />
                        <Form.Input icon='lock'
                            iconPosition='left'
                            label='Şifre'
                            type='password'
                            onChange={(password) => this.setState({ password: password.target.value })}

                        />
                        <Button content='Ekle' primary onClick={() => this.addAdmin()} disabled={this.state.btnDisable} />
                    </Form>
                </div>


            </Container>
        )
    }
}

const mapStateToProps = state => {

    return {
        isLogin: state.authReducer.isLogin
    }
}
export default connect(mapStateToProps)(AdminRegister);