import React, { Component } from 'react'

import { Container, Button,  Header, Form } from 'semantic-ui-react';
import Axios from 'axios';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
class AdminDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
    
            id:null,
            fullname: '',
            mail: '',
            password: '',
            btnDisable:false,
            onFinished:false,
            formLoading:true
        }
    }
    componentDidMount() {
        //let params = new URLSearchParams(location.id);
        const { match: { params } } = this.props;
       this.setState({
           id:params.detailsid
       },()=>this.details())
   
    }
    details=()=>{
        Axios.get(`https://bisorye.herokuapp.com/api/admin/admindetails/${this.state.id}`).then((result)=>{
            console.log('data',result.data);
            this.setState({
                id:result.data._id,
                fullname:result.data.full_name,
                mail:result.data.email,
                password:result.data.password,
                formLoading:false
              
            })
        }).catch(err=>{
            this.setState({failed:true})
        })
    }
    updateAdmin=async ()=>{
        this.setState({btnDisable:true,formLoading:true})
        const {id,fullname,mail,password} = this.state;
        const updateData = {           
            full_name:fullname,
            email:mail,
            password:password
        }
        try {
            const result = await Axios.put(`https://bisorye.herokuapp.com/api/admin/adminupdate/${id}`,updateData);
            console.log('result',result)
            this.setState({
                onFinished:true,
                formLoading:false
            })
        } catch (error) {
            console.log('resultError',error);
        }
       
    }
   
   

    render() {
        if (!this.props.isLogin) return <Redirect to='/login' />
        if(this.state.onFinished) return <Redirect to='/admin/adminlist' />
        return (
            <Container fluid >
                <Header as='h1'>Detay</Header>
                <div style={{ width: 400, marginLeft: 10 }}   >
                    <Form loading={this.state.formLoading} >
                        <Form.Input icon='user'
                            iconPosition='left'
                            label='Ad Soyad'
                            placeholder='Ad-Soyad Giriniz..'
                            onChange={(fullname) => this.setState({ fullname: fullname.target.value })}
                            value={this.state.fullname}
                        />
                        <Form.Input icon='mail'
                            iconPosition='left'
                            label='Mail Adresi'
                            placeholder='Mail Adresi Giriniz..'
                            onChange={(mail) => this.setState({ mail: mail.target.value })}
                            value={this.state.mail}
                        />
                        <Form.Input icon='lock'
                            iconPosition='left'                        
                            label='Şifre'
                            
                            onChange={(password) => this.setState({ password: password.target.value })}
                            value={this.state.password}
                        />
                        <Button fluid onClick={()=>this.updateAdmin()} disabled={this.state.btnDisable} >   Güncelle </Button>
                    </Form>
                </div>


            </Container>
        )
    }
}

const mapStateToProps = (state,props) => {
    
    return {
        isLogin: state.authReducer.isLogin
    }
}
export default connect(mapStateToProps)(AdminDetails);