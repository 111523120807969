/* global google */
import React, { Component } from "react";
import {
  Container,
  Button,
  Form,
  Grid,
  Segment,
  Header,
  Divider
} from "semantic-ui-react";
import Axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Current from "../../extension/Map";
import _ from "lodash";

const cityJson = require("../../data/city.json");
const districtJson = require("../../data/district.json");
const mutfak = require("../../data/mutfak.json");
class LocationRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LokasyonName: "",
      LokasyonType: 1,
      LokasyonHijyen: 0,
      LokasyonDenetleyen: "",
      LokasyonDenetlenmeTarihi: new Date(),
      LokasyonCity: "34",
      LokasyonDistrict: "0",
      LokasyonPostCode: "",
      LokasyonAdress: "",
      LokasyonAlternative: {
        gluten: false,
        diyet: false,
        vejeteryan: false,
        helal: false,
        alkolsuz: false,
        organik: false
      },
      LokasyonCoordinatte: {
        latitude: 41.02908,
        longitude: 28.98293
      },
      district: [
        {
          value: "0",
          text: "İlçe Seçiniz"
        }
      ],
      btnDisable: false,
      formLoading: false,
      onFinished: false,
      lokasyonTypeList: mutfak
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(date) {
    this.setState({
      LokasyonDenetlenmeTarihi: date
    });
  }
  componentDidMount() {
    this.setState({
      formLoading: false
    });
    this.updateDistrict();
  }
  updateDistrict = async () => {
    const value = this.state.LokasyonCity;

    let district = [
      {
        value: "0",
        city_value: "0",
        text: "İlçe Seçiniz"
      }
    ];
    for (const key in districtJson) {
      if (districtJson.hasOwnProperty(key)) {
        const element = districtJson[key];

        if (element.city_value === value) {
          district.push(element);
        }
      }
    }
    this.setState({ district });
  };
  addLocation = async () => {
    this.setState({ btnDisable: true, formLoading: true });
    const {
      LokasyonName,
      LokasyonType,
      LokasyonHijyen,
      LokasyonDenetleyen,
      LokasyonDenetlenmeTarihi,
      LokasyonCity,
      LokasyonDistrict,
      LokasyonPostCode,
      LokasyonAdress,
      LokasyonAlternative,
      LokasyonCoordinatte
    } = this.state;
    const registerData = {
      LokasyonName,
      LokasyonType,
      LokasyonHijyen,
      LokasyonDenetleyen,
      LokasyonDenetlenmeTarihi,
      LokasyonCity,
      LokasyonDistrict,
      LokasyonPostCode,
      LokasyonAdress,
      LokasyonAlternative,
      LokasyonCoordinatte
    };

    try {
      const result = await Axios.post(
        `https://bisorye.herokuapp.com/api/admin/locationregister/`,
        registerData
      );

      if (result.data.status) {
        this.setState({
          onFinished: true,
          formLoading: false
        });
      } else {
        console.log("Hata var", result.data.message);
        alert("Eksik alan bırakmayınız.");
        this.setState({
          formLoading: false
        });
      }
    } catch (error) {
      console.log("resultError", error);
      alert("Hata var");
    }
  };
  getCity = async value => {
    console.log("city", value);
    try {
      const result = _.find(cityJson, { text: value.toUpperCase() });
      return result.value;
    } catch (error) {
      return "0";
    }
  };
  getDistrict = async search => {
    try {
      let result;
      const newtext = search.toUpperCase();
      const one = newtext.replace(/I/g, "İ");
      const two = one.replace(/U/g, "Ü");
      result = _.find(districtJson, { text: two });
      if (result === undefined) {
        result = _.find(districtJson, { text: one });
        return result.value;
      } else {
        return result.value;
      }
    } catch (error) {
      return "0";
    }
  };
  getUpdateDetails = async values => {
    const LokasyonAdress = values.full_adress;
    const LokasyonCity = await this.getCity(values.il);
    const LokasyonDistrict = await this.getDistrict(values.ilce);
    let LokasyonName, LokasyonCoordinatte;
    try {
      LokasyonName = values.title;
    } catch (error) {
      LokasyonName = "";
    }
    LokasyonCoordinatte = values.LokasyonCoordinatte;
    this.setState(
      {
        LokasyonAdress,
        LokasyonCity,
        LokasyonName,
        LokasyonDistrict,
        LokasyonCoordinatte
      },
      () => {
        this.updateDistrict();
      }
    );
  };
  getMarkerDrag = async values => {
    console.log("markerDrag", values);
    try {
      const LokasyonAdress = values.full_adress;
      const LokasyonCity = await this.getCity(values.il);
      const LokasyonDistrict = await this.getDistrict(values.ilce);
      const LokasyonCoordinatte = {
        latitude: values.coordinatte.newLat,
        longitude: values.coordinatte.newLng
      };
      this.setState(
        {
          LokasyonAdress,
          LokasyonCity,
          LokasyonDistrict,
          LokasyonCoordinatte
        },
        () => {
          this.updateDistrict();
        }
      );
    } catch (error) {
      console.log("error");
    }
  };
  // <Current locationMarker={this.state.LokasyonCoordinatte} onChange={(LokasyonCoordinatte) => this.setState({ LokasyonCoordinatte })} />

  render() {
    if (!this.props.isLogin) return <Redirect to="/login" />;
    if (this.state.onFinished) return <Redirect to="/admin/locationlist" />;
    return (
      <Container fluid>
        <h1> Lokasyon Ekle </h1>
        <Segment>
          <Current
            onChange={LokasyonCoordinatte =>
              this.setState({ LokasyonCoordinatte }, () =>
                console.log("lokasyon", this.state.LokasyonCoordinatte)
              )
            }
            google={this.props.google}
            height="300px"
            zoom={15}
            deneme={value => this.getUpdateDetails(value)}
            center={{
              lat: this.state.LokasyonCoordinatte.latitude,
              lng: this.state.LokasyonCoordinatte.longitude
            }}
            markerDrag={value => this.getMarkerDrag(value)}
          />
        </Segment>
        <Grid columns={3} divided stretched stackable>
          <Grid.Row>
            <Grid.Column>
              <Segment>
                <Header> Genel Bilgiler </Header>
                <Divider />
                <Form loading={this.state.formLoading}>
                  <Form.Input
                    icon="address card"
                    iconPosition="left"
                    label="İşletmenin İsmi"
                    placeholder="İşletmenin İsmini Giriniz.."
                    onChange={LokasyonName =>
                      this.setState({ LokasyonName: LokasyonName.target.value })
                    }
                    value={this.state.LokasyonName}
                  />
                  <Form.Dropdown
                    label="İşletme Tipi"
                    placeholder="Seçiniz"
                    fluid
                    selection
                    search
                    options={mutfak}
                    onChange={(e, { value }) =>
                      this.setState({ LokasyonType: value })
                    }
                    value={this.state.LokasyonType}
                  />
                </Form>
              </Segment>
              <Segment>
                <Header> Denetim Bilgisi </Header>
                <Divider />
                <Form loading={this.state.formLoading}>
                  <Form.Dropdown
                    label="Gıda Güvenliği Derecesi "
                    placeholder="Seçiniz"
                    fluid
                    disabled
                    options={[{ text: "A", value: 0 }]}
                    onChange={(e, { value }) =>
                      this.setState({ LokasyonHijyen: value })
                    }
                    value={this.state.LokasyonHijyen}
                  />
                  <Form.Input
                    icon="bullseye"
                    iconPosition="left"
                    label="Denetleyen Firma"
                    placeholder="Ad-Soyad Giriniz.."
                    onChange={LokasyonDenetleyen =>
                      this.setState({
                        LokasyonDenetleyen: LokasyonDenetleyen.target.value
                      })
                    }
                    value={this.state.LokasyonDenetleyen}
                  />
                  <Segment>
                    <Header> Denetlenme Tarihi</Header>
                    <DatePicker
                      selected={this.state.LokasyonDenetlenmeTarihi}
                      onChange={this.handleChange}
                      dateFormat="dd/MM/yyyy"
                    />
                  </Segment>
                </Form>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment>
                <Header> Konum Bilgisi </Header>
                <Divider />
                <Form loading={this.state.formLoading}>
                  <Form.Dropdown
                    label="İl"
                    fluid
                    selection
                    options={cityJson}
                    onChange={(e, { value }) =>
                      this.setState({ LokasyonCity: value }, () =>
                        this.updateDistrict()
                      )
                    }
                    search
                    value={this.state.LokasyonCity}
                  />
                  <Form.Dropdown
                    label="İlçe"
                    placeholder="Seçiniz"
                    fluid
                    selection
                    search
                    options={this.state.district}
                    onChange={(e, { value }) =>
                      this.setState({ LokasyonDistrict: value })
                    }
                    value={this.state.LokasyonDistrict}
                  />
                  <Form.Input
                    icon="point"
                    iconPosition="left"
                    label="Posta Kodu"
                    placeholder="Örn: 34810"
                    onChange={LokasyonPostCode =>
                      this.setState({
                        LokasyonPostCode: LokasyonPostCode.target.value
                      })
                    }
                    value={this.state.LokasyonPostCode}
                  />

                  <Form.TextArea
                    label="Adress"
                    placeholder="Örn: Demokrasi Cad No:8 Çekmeköy"
                    value={this.state.LokasyonAdress}
                    onChange={LokasyonAdress =>
                      this.setState({
                        LokasyonAdress: LokasyonAdress.target.value
                      })
                    }
                  />
                </Form>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment>
                <Header> Alternatif Beslenme Seçenekleri </Header>
                <Divider />
                <Form loading={this.state.formLoading}>
                  <Form.Checkbox
                    label="Glutensiz  Yemek Seçeneği"
                    onChange={() =>
                      this.setState(prevState => ({
                        LokasyonAlternative: {
                          ...prevState.LokasyonAlternative,
                          gluten: !this.state.LokasyonAlternative.gluten
                        }
                      }))
                    }
                    checked={this.state.LokasyonAlternative.gluten}
                  />
                  <Form.Checkbox
                    label="Diyet Yemek Seçeneği"
                    onChange={() =>
                      this.setState(prevState => ({
                        LokasyonAlternative: {
                          ...prevState.LokasyonAlternative,
                          diyet: !this.state.LokasyonAlternative.diyet
                        }
                      }))
                    }
                    checked={this.state.LokasyonAlternative.diyet}
                  />
                  <Form.Checkbox
                    label="Vejeteryan Yemek Seçeneği"
                    onChange={() =>
                      this.setState(prevState => ({
                        LokasyonAlternative: {
                          ...prevState.LokasyonAlternative,
                          vejeteryan: !this.state.LokasyonAlternative.vejeteryan
                        }
                      }))
                    }
                    checked={this.state.LokasyonAlternative.vejeteryan}
                  />
                  <Form.Checkbox
                    label="Organik Gıda  Seçeneği"
                    onChange={() =>
                      this.setState(prevState => ({
                        LokasyonAlternative: {
                          ...prevState.LokasyonAlternative,
                          organik: !this.state.LokasyonAlternative.organik
                        }
                      }))
                    }
                    checked={this.state.LokasyonAlternative.organik}
                  />
                  <Form.Checkbox
                    label="Helal Et Seçeneği"
                    onChange={() =>
                      this.setState(prevState => ({
                        LokasyonAlternative: {
                          ...prevState.LokasyonAlternative,
                          helal: !this.state.LokasyonAlternative.helal
                        }
                      }))
                    }
                    checked={this.state.LokasyonAlternative.helal}
                  />
                  <Form.Checkbox
                    label="Alkollü İçki Seçeneği"
                    onChange={() =>
                      this.setState(prevState => ({
                        LokasyonAlternative: {
                          ...prevState.LokasyonAlternative,
                          alkolsuz: !this.state.LokasyonAlternative.alkolsuz
                        }
                      }))
                    }
                    checked={this.state.LokasyonAlternative.alkolsuz}
                  />
                </Form>
              </Segment>
              <Button
                onClick={() => this.addLocation()}
                disabled={this.state.btnDisable}
              >
                {" "}
                Kaydet{" "}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLogin: state.authReducer.isLogin
  };
};
export default connect(mapStateToProps)(LocationRegister);
