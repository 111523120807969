export const AUTH_LOGIN='AUTH_LOGIN';

export function authLogin(data){
    console.log('data',data);
	return{
        type:AUTH_LOGIN,
        payload:{
            data
        }
    }
}