import React, { Component } from 'react'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

const MapWithAMarker = withScriptjs(withGoogleMap(props =>
  <GoogleMap
    defaultZoom={12}
    defaultCenter={props.defaultCenter}
    onClick={props.onClick}
    
    
  >
    {props.lat ? ((
        <Marker
        position={{ lat: props.lat, lng: props.lng }}
      />
    )):null}
  
  </GoogleMap>
));



export class current extends Component {
 
  constructor(props){
    super(props);
    this.state={
      latitude:null,
      longitude:null,
    }
  
  }
  componentDidMount = () => {
 
   this.getLocation()
  }
  getLocation=()=>{
    if(this.props.locationMarker){
  
      this.setState({
        latitude:this.props.locationMarker.latitude,
        longitude:this.props.locationMarker.longitude,
      })
    }
  }
  render() {
    return (
      <div>

        <MapWithAMarker
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBtZlEIZZ8ivxrmC0sOVJIXNujcPbIfUEs&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            lat={this.state.latitude}
            lng={this.state.longitude}
            defaultZoom={this.state.latitude ? 12 : 8}
            defaultCenter={{ lat: this.state.latitude ? this.state.latitude :41.015137, lng: this.state.longitude ? this.state.longitude : 28.979530 }}
            onClick={x => {
              const latitude = x.latLng.lat();
              const longitude = x.latLng.lng();

              this.setState({latitude,longitude},()=>{
                console.log('son deger',this.state);
                this.props.onChange({latitude,longitude})
              })
            }}

          />
      </div>
    )
  }
}

export default current
