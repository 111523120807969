import React, { Component } from 'react';
import Layout from './components/layout/Layout';
import { Switch, Route } from 'react-router-dom';

import './styles/index.css'
import 'semantic-ui-css/semantic.min.css'
import {
  
  Home,

  Settings,
  

  Login,
  AdminList,
  AdminDetails,
  AdminRegister,
  UserDetails,
  UserList,
  LocationDetails,
  LocationList,
  LocationRegister,
  ReportDetails,
  ReportList,
 
    KurumsalDetails,
    KurumsalList,
    OfferList,
    RefferanceList,
    Suggest,
  Supervision,
  FoodAnalyzer,
  HygieneTraining

} from './components/page/index'

class App extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/login" render={() => { return (<Login />) }} />

          <Layout>
            <Route exact path="/admin" component={Home} />       
            <Route path="/admin/settings" exact strict component={Settings} />           
           
            <Route path="/admin/adminlist" exact strict component={AdminList} />
            <Route path='/admin/admindetails/:detailsid' exact strict component={AdminDetails} />
            <Route path="/admin/adminregister" exact strict component={AdminRegister} />

            <Route path="/admin/userlist" exact strict component={UserList} />
            <Route path="/admin/userdetails/:userid" exact strict component={UserDetails} />

            <Route path="/admin/locationlist" exact strict component={LocationList} />
            <Route path='/admin/locationdetails/:locationid' exact strict component={LocationDetails} />
            <Route path="/admin/locationregister" exact strict component={LocationRegister} />

            <Route path="/admin/report" exact strict component={ReportList} />
            <Route path="/admin/reportdetails/:reportid" exact strict component={ReportDetails} />

       
            <Route path="/admin/kurumsal" exact strict component={KurumsalList} />
            <Route path="/admin/kurumsaldetails/:kurumsalid" exact strict component={KurumsalDetails} />
            <Route path="/admin/offer" exact strict component={OfferList} />
            <Route path="/admin/refferance" exact strict component={RefferanceList} />

            <Route path="/admin/suggest" exact strict component={Suggest} />
            <Route path="/admin/supervision" exact strict component={Supervision} />
            <Route path="/admin/hygienetraining" exact strict component={HygieneTraining} />
            <Route path="/admin/foodanalyzer" exact strict component={FoodAnalyzer} />
          </Layout>

          <Route render={() => { return (<div><h1> 404 page error</h1></div>) }} />
        </Switch>
      </div>
    );
  }
}

export default App;

// eski    <Route path="/admin/adminlist" exact strict render={() => { return (<Layout><Admin /></Layout>) }} />
// eski  <Route path="/admin/userlist" exact strict component={Userlist} />

// eski  <Route path="/admin/location" exact strict component={Location} />

// eski  <Route path="/admin/sikayet" exact strict component={Sikayet} />

// eski       <Route path="/admin/feedback" exact strict component={Feedback} />