import React, { Component } from 'react';
import { Button, Form, Grid, Image, Segment } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import {  Redirect} from 'react-router-dom';
import {authLogin} from '../../../redux/actions/authActions';
import {connect} from 'react-redux';
import Axios from 'axios';
class AuthPage extends Component {
  state={
      isLogin:false,
      email:'',
      password:''
  }
  componentDidMount() {
    const res = localStorage.getItem('isLogin');
    if(res){
      this.props.login(true);  
    }
  }
  
 loginButton = async  () => {
    
    const {email,password} = this.state;
    if(email && password ){
      const params={
        mail:email,
        password:password
      }
        const res = await Axios.post('https://bisorye.herokuapp.com/api/admin/login',params);
        if(res.data.status){
          localStorage.setItem('isLogin', res.data.status);
          this.props.login(true);     
        }else{
          console.log('mesaj',res.data.veri)
        }
    }
    
 }
 render(){
    if (this.props.isLogin) return <Redirect to={{pathname:'/admin'}} />;
     return(
        <div className='login-form'>
    {/*
      Heads up! The styles below are necessary for the correct render of this example.
      You can do same with CSS, the main idea is that all the elements up to the `Grid`
      below must have a height of 100%.
    */}
    <style>{`
      body > div,
      body > div > div,
      body > div > div > div.login-form {
        height: 100%;
      }
    `}</style>
    <Grid textAlign='center' style={{ height: '100%',backgroundColor:'red' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 450,backgroundColor:'white' }}>
        <Segment >
                 <Image src="http://admin.bisorye.com/assets/images/logo.png" size='small' verticalAlign='middle' />      
           
        </Segment>
        
        <Form size='large'>
          <Segment stacked>
            <Form.Input 
                        fluid icon='user' 
                        iconPosition='left' 
                        placeholder='E-mail adresiniz' 
                        onChange={(email)=>this.setState({email:email.target.value})}
                        value={this.state.email}
                        />
            <Form.Input
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='Şifreniz.'
              type='password'
              onChange={(password)=>this.setState({password:password.target.value})}
              value={this.state.password}
            />

            <Button color='teal' fluid size='large'    onClick={()=>this.loginButton()} >
              Giriş Yap
            </Button>
          </Segment>
        </Form>
       
      </Grid.Column>
    </Grid>
  </div>
     )
    
  }
 
}
const mapStateToProps = state =>{

  return{
    isLogin:state.authReducer.isLogin
  }
}
const mapDispatchToProps={
  login:authLogin
}
export default connect(mapStateToProps,mapDispatchToProps)(AuthPage)