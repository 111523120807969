import React, { Component } from 'react'

import { Container, Button, Icon, Table,Modal } from 'semantic-ui-react';
import Axios from 'axios';
import { connect } from 'react-redux';
import {  Redirect,Link } from 'react-router-dom';
import _ from 'lodash';
class AdminList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userlist: [],
            open:false
        }
    }
    componentWillMount = async () => {
        const url = 'https://bisorye.herokuapp.com/api/admin/alladmin';
        try {
            const result = await Axios.get(url);
            //console.log('result',result.data);
            let userlist = [];
            for (const key in result.data) {
                if (result.data.hasOwnProperty(key)) {
                    const element = result.data[key];

                    userlist.push(element);
                }
            }
            this.setState({ userlist });
            console.log('userlist', userlist);
        } catch (error) {
            console.log('error willmount', error)
        }

    }
    show = (id) => this.setState({id, open: true })
    close = () => this.setState({ open: false,id:undefined })
    onDelete=async()=>{
        try {
            const result =  await Axios.delete(`https://bisorye.herokuapp.com/api/admin/admindeleted/${this.state.id}`)
            const userlist = _.remove(this.state.userlist,{_id:this.state.id});  
            this.setState({open:false,id:undefined})
          
        } catch (error) {
            console.log('error',error)
        }
    }


    render() {
        if (!this.props.isLogin ) return <Redirect to='/login' />
        
        return (
      
            <Container fluid   >
                <h1>  Yöneticiler  </h1>
                <Table celled compact definition unstackable>
                    <Table.Header fullWidth>
                        <Table.Row>
                            <Table.HeaderCell />

                            <Table.HeaderCell>Ad-Soyad</Table.HeaderCell>
                            <Table.HeaderCell>Mail</Table.HeaderCell>
                            <Table.HeaderCell>İşlem</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {this.state.userlist.map((value, index) => {
                            return (
                                <Table.Row key={value._id} >
                                    <Table.Cell collapsing>
                                    <Button size='tiny' onClick={()=>this.show(value._id)} >Sil</Button>
                                    </Table.Cell>


                                    <Table.Cell>{value.full_name}</Table.Cell>
                                    <Table.Cell>{value.email}</Table.Cell>

                                    <Table.Cell> <Link to={`/admin/admindetails/${value._id}`}  ><Button>Detay</Button></Link></Table.Cell>
                                </Table.Row>
                            )
                        })}


                    </Table.Body>

                    <Table.Footer fullWidth>
                        <Table.Row>
                            <Table.HeaderCell />
                            <Table.HeaderCell colSpan='4'>
                                <Link  to='/admin/adminregister' >
                                <Button floated='right' icon labelPosition='left' primary size='small'  >
                                    <Icon name='user' /> Yönetici Ekle
                                </Button>
                                </Link>
                              
                               
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
                <Modal size='small' open={this.state.open} onClose={this.close}>
          <Modal.Header>Silme İşlemi Onayı</Modal.Header>
          <Modal.Content>
            <p>Silmek istediğinize emin misiniz?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button negative icon='x' labelPosition='right' content='Hayır' onClick={()=>this.setState({open:false,id:undefined})} />
            <Button positive icon='checkmark' labelPosition='right' content='Evet'  onClick={()=>this.onDelete()} /> 
          </Modal.Actions>
        </Modal>
            </Container>
         
        )
    }
}

const mapStateToProps = state => {

    return {
        isLogin: state.authReducer.isLogin
    }
}
export default connect(mapStateToProps)(AdminList);