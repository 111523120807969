
import React, { Component } from 'react'
import { Container, Button, Icon, Table,Modal } from 'semantic-ui-react';
import Axios from 'axios';

import "react-datepicker/dist/react-datepicker.css";
import {connect} from 'react-redux';
import {Redirect,Link} from 'react-router-dom';
import _ from 'lodash';
class KurumsalList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      kurumsal: [],
      open:false
    }

  }
 
  componentDidMount =  () => {
    this.allLocation();
    
  } 
  allLocation=async ()=>{
    const url = 'https://bisorye.herokuapp.com/api/admin/allkurumsal';
    try {
      const result = await Axios.get(url);
      console.log('result',result.data);
       let kurumsal = [];
       for (const key in result.data) {
         if (result.data.hasOwnProperty(key)) {
           const element = result.data[key];
          
           kurumsal.push(element);
         }
       }
       this.setState({kurumsal});
       
    } catch (error) {
      console.log('error willmount',error)
    }
  }
  show = (id) => this.setState({id, open: true })
  close = () => this.setState({ open: false,id:undefined })
  onDelete=async()=>{
    try {
        const result =  await Axios.delete(`https://bisorye.herokuapp.com/api/admin/kurumsaldeleted/${this.state.id}`)
        const kurumsal = _.remove(this.state.kurumsal,{_id:this.state.id});  
        this.setState({open:false,id:undefined})
       
    } catch (error) {
        console.log('error',error)
    }
  }
  getLokasyonType=(value)=>{
    switch (value) {
      case 1:
          return "Restoran/Cafe"
      case 2:
          return "Market"
      case 3:
          return "AVM"
      case 4:
          return "Gıda Üretim İşletmesi"
      case 5:
          return "Yemek Fabrikası(Catering)"
      case 6:
          return "Kasap Dükkanı"

      default:
        return "Kategorisiz"
    }
  }
  render() {
    if(!this.props.isLogin) return <Redirect to='/login' />
    return (
        <Container fluid   >
            <h1>  Noktalar  </h1>
            <Table celled compact definition unstackable>
            <Table.Header fullWidth>
              <Table.Row>
                <Table.HeaderCell />
              
                <Table.HeaderCell>Adı</Table.HeaderCell>
                <Table.HeaderCell>Yetkili</Table.HeaderCell>
                <Table.HeaderCell>Telefon</Table.HeaderCell>
                <Table.HeaderCell>Mail</Table.HeaderCell>
                <Table.HeaderCell>Adresi</Table.HeaderCell>
                <Table.HeaderCell>Tipi</Table.HeaderCell>               
                <Table.HeaderCell>Yayınlanmış</Table.HeaderCell>               
                <Table.HeaderCell>Detay</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
  
            <Table.Body>
  
            {this.state.kurumsal.map((value,index)=>{
              return(
                <Table.Row key={value._id} >
                <Table.Cell collapsing>
                <Button size='tiny' onClick={()=>this.show(value._id)} >Sil</Button>
                </Table.Cell>           
                <Table.Cell>{value.LokasyonName}</Table.Cell>
                <Table.Cell>{value.LokasyonYetkiliName}</Table.Cell>
                <Table.Cell>{value.LokasyonYetkiliPhone}</Table.Cell>
                <Table.Cell>{value.LokasyonYetkiliMail}</Table.Cell>           
                <Table.Cell>{value.LokasyonAdress}</Table.Cell>
                <Table.Cell>{this.getLokasyonType(value.LokasyonType)}</Table.Cell>
                <Table.Cell>{value.published ? 'Yayında' : 'Yayında Değil'}</Table.Cell>
                <Table.Cell> {!value.published ? ((<Link to={`/admin/kurumsaldetails/${value._id}`} ><Button >Detay</Button></Link>)) :((<Button disabled>Detay</Button>)) }
                </Table.Cell>
              </Table.Row>
              )
            })}
  
  
            </Table.Body>
  
            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell colSpan='8'>
              
                 
               
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
          <Modal size='small' open={this.state.open} onClose={this.close}>
          <Modal.Header>Silme İşlemi Onayı</Modal.Header>
          <Modal.Content>
            <p>Silmek istediğinize emin misiniz?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button negative icon='x' labelPosition='right' content='Hayır' onClick={()=>this.setState({open:false,id:undefined})} />
            <Button positive icon='checkmark' labelPosition='right' content='Evet'  onClick={()=>this.onDelete()} /> 
          </Modal.Actions>
        </Modal>
          </Container>
        )
  }
}


const mapStateToProps = state =>{

  return{
    isLogin:state.authReducer.isLogin
  }
}
export default connect(mapStateToProps)(KurumsalList);