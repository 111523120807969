import {AUTH_LOGIN} from '../actions/authActions';
const initialState = {
	isLogin:false
};
export default (state = initialState, action) => {
  
  switch (action.type) {
      case AUTH_LOGIN:
          return {isLogin:action.payload.data}  
        
  
      default:
          return state;
  }
}
