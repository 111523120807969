import React, { Component } from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  InfoWindow,
  Marker
} from "react-google-maps";
import Geocode from "react-geocode";
import Autocomplete from "react-google-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import _ from "lodash";
Geocode.setApiKey("AIzaSyBtZlEIZZ8ivxrmC0sOVJIXNujcPbIfUEs");
Geocode.enableDebug();

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      city: "",
      area: "",
      state: "",
      mapPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng
      },
      markerPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng
      }
    };
  }
  /**
   * Get the current address from the default map position and set those values in the state
   */
  componentDidMount() {
    Geocode.fromLatLng(
      this.state.mapPosition.lat,
      this.state.mapPosition.lng
    ).then(
      response => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          state = this.getState(addressArray);

        console.log("city", city, area, state);

        this.setState({
          address: address ? address : "",
          area: area ? area : "",
          city: city ? city : "",
          state: state ? state : ""
        });
      },
      error => {
        console.error(error);
      }
    );
  }
  /**
   * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
   *
   * @param nextProps
   * @param nextState
   * @return {boolean}
   */
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.markerPosition.lat !== this.props.center.lat ||
      this.state.address !== nextState.address ||
      this.state.city !== nextState.city ||
      this.state.area !== nextState.area ||
      this.state.state !== nextState.state
    ) {
      return true;
    } else if (this.props.center.lat === nextProps.center.lat) {
      return false;
    }
  }
  /**
   * Get the city and set the city input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getCity = addressArray => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_2" === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };
  /**
   * Get the area and set the area input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getArea = addressArray => {
    let area = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "sublocality_level_1" === addressArray[i].types[j] ||
            "locality" === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };
  /**
   * Get the address and set the address input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getState = addressArray => {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };
  /**
   * And function for city,state and address input
   * @param event
   */
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  /**
   * This Event triggers when the marker window is closed
   *
   * @param event
   */
  onInfoWindowClose = event => {};

  /**
   * When the marker is dragged you get the lat and long using the functions available from event object.
   * Use geocode to get the address, city, area and state from the lat and lng positions.
   * And then set those values in the state.
   *
   * @param event
   */
  onMarkerDragEnd = event => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();

    Geocode.fromLatLng(newLat, newLng).then(
      response => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          state = this.getState(addressArray),
          markerPosition = {
            lat: newLat,
            lng: newLng
          },
          mapPosition = {
            lat: newLat,
            lng: newLng
          };
        this.setState(
          {
            address: address ? address : "",
            area: area ? area : "",
            city: city ? city : "",
            state: state ? state : "",
            markerPosition,
            mapPosition
          },
          () => {
            console.log("this.state", this.state);
            let adres = {
              full_adress: address,
              il: state,
              ilce: city,
              coordinatte: {
                newLat,
                newLng
              }
            };
            this.props.markerDrag(adres);
          }
        );
      },
      error => {
        console.error(error);
      }
    );
  };

  /**
   * When the user types an address in the search box
   * @param place
   */
  onPlaceSelected = place => {
    console.log("plc", place);
    const address = place.formatted_address,
      addressArray = place.address_components,
      city = this.getCity(addressArray),
      area = this.getArea(addressArray),
      state = this.getState(addressArray),
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();
    // Set these values in the state.
    this.setState({
      address: address ? address : "",
      area: area ? area : "",
      city: city ? city : "",
      state: state ? state : "",
      markerPosition: {
        lat: latValue,
        lng: lngValue
      },
      mapPosition: {
        lat: latValue,
        lng: lngValue
      }
    });
  };

  getLat = adress => {
    console.log("adress", adress);
    console.log("lastadress", adress.terms[adress.terms.length - 1]);
    try {
      let adres;
      const adresBol = adress.description.split(","),
        adresililce = adresBol[adresBol.length - 2].split("/"),
        ilce = adresililce[0].replace(" ",""),
        il = adresililce[1],
        ulke = adresBol[adresBol.length - 1];
      if (adress.terms[adress.terms.length - 1].value == "Türkiye") {
        adres = {
          ilce,
          il,
          ulke,
          full_adress: adress.description
        };
      } else {
		adres = {
			ilce,
			il,
			ulke,
			full_adress: adress.description
		  };
      }
	
      Geocode.fromAddress(adress.description).then(
        response => {
          const { lat, lng } = response.results[0].geometry.location;
          console.log(lat, lng);
          const address = adress.description;
          const city = adres.ilce;
          const area = adres.il;
          const mapPosition = {
            lat,
            lng
          };
          const markerPosition = {
            lat,
            lng
          };
          adres.LokasyonCoordinatte = {
            latitude: lat,
            longitude: lng
          };
          this.setState(
            { address, city, area, mapPosition, markerPosition },
            () => this.props.deneme(adres)
          );
        },
        error => {
          console.error(error);
        }
      );
    } catch (error) {
      console.log("error", error);
    }

    /*
		console.log('adress',adress.place_id)
		const proxy_url = 'https://cors-anywhere.herokuapp.com/';
	   const google_api_key='AIzaSyBtZlEIZZ8ivxrmC0sOVJIXNujcPbIfUEs';
	   const google_api_url = `${proxy_url}https://maps.googleapis.com/maps/api/place/details/json?placeid=${adress.place_id}&fields=name,geometry,formatted_address&key=${google_api_key}`
	   console.log('googleapi',google_api_url)
	   
	   fetch(google_api_url).then(response => response.json()).then(data=>{
		   const location = data.result.geometry.location;
		   console.log('data',location);
		   this.props.deneme(location);
	   });
	   */
  };
  render() {
    const AsyncMap = withScriptjs(
      withGoogleMap(props => (
        <GoogleMap
          google={this.props.google}
          defaultZoom={this.props.zoom}
          defaultCenter={{
            lat: this.state.mapPosition.lat,
            lng: this.state.mapPosition.lng
          }}
        >
          {/* InfoWindow on top of marker */}
          <InfoWindow
            onClose={this.onInfoWindowClose}
            position={{
              lat: this.state.markerPosition.lat + 0.0018,
              lng: this.state.markerPosition.lng
            }}
          >
            <div>
              <span style={{ padding: 0, margin: 0 }}>
                {this.state.address}
              </span>
            </div>
          </InfoWindow>
          {/*Marker*/}
          <Marker
            google={this.props.google}
            name={"Dolores park"}
            draggable={true}
            onDragEnd={this.onMarkerDragEnd}
            position={{
              lat: this.state.markerPosition.lat,
              lng: this.state.markerPosition.lng
            }}
          />
          <Marker />
          {/* For Auto complete Search Box */}
          <div style={{ marginTop: 5 }}>
            <GooglePlacesAutocomplete
              onSelect={value => this.getLat(value)}
              placeholder="Adres Giriniz"
              inputStyle={{ width: "100%" }}
            />
          </div>
        </GoogleMap>
      ))
    );
    let map;
    if (this.props.center.lat !== undefined) {
      map = (
        <div>
          <AsyncMap
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBtZlEIZZ8ivxrmC0sOVJIXNujcPbIfUEs&libraries=places"
            loadingElement={<div style={{ height: `90%` }} />}
            containerElement={<div style={{ height: this.props.height }} />}
            mapElement={<div style={{ height: `90%` }} />}
          />
        </div>
      );
    } else {
      map = <div style={{ height: this.props.height }} />;
    }
    return map;
  }
}
export default Map;
