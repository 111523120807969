import React, {Component} from 'react'
import {
        Image,     
        Menu, 
        Popup,
        Button,        
        Icon
       } from 'semantic-ui-react'
import {connect} from 'react-redux';
import {
  authLogin
} from '../../redux/actions/authActions';   
import {
  settings_toggle
} from '../../redux/actions/settingsActions';           
const CardRender = props => (
   <div className="display-inline" onClick={()=>props.onClick()} >            
      <Image
        circular
        size={"mini"}
        src="https://react.semantic-ui.com/images/avatar/small/jenny.jpg"
      />
      Orhan ÖKSÜZ
    </div>
)     
const logo = 'https://logos.textgiraffe.com/logos/logo-name/Admin-designstyle-kiddo-m.png';  
//const logo = 'http://admin.bisorye.com/assets/images/logo.png';  
class TopMenu extends Component {
state={
  opened:false
}
  logout(){
    localStorage.removeItem('isLogin')
    this.props.login(false);  
  }
  render() {

    return (
      <Menu fixed="top" className="top-menu">
        <Menu.Item className="logo-space-menu-item">
          <div className="display-inline logo-space">
            <Image src={logo} />
         
          </div>
        </Menu.Item>       
        <Menu.Item
          className="no-border"
          onClick={()=>this.props.toggle()}
        >
          <Icon name="bars" />
        </Menu.Item>
        <Menu.Menu position="right">
         
          <Menu.Item className="no-border" position="right">
          <Popup trigger={<CardRender onClick={()=>this.setState({opened:!this.state.opened})} />}   
                position='bottom right'
               
                size="small"
                basic
                on='click'    
                open={this.state.opened}
                >
                 
                
                  <Button onClick={()=>this.logout()} >Oturumu Kapat</Button>
             
            </Popup>
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    );
  }
}

const mapStateToProps = state => {
  
  return {
      isLogin: state.authReducer.isLogin,
    
  }
}
const mapDispatchToProps={
  login:authLogin,
  toggle:settings_toggle
}
export default connect(mapStateToProps,mapDispatchToProps)(TopMenu);
