import React,{Component} from 'react'

import { Container, } from 'semantic-ui-react';
import Axios from 'axios';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
class ReportDetails extends Component {
  constructor(props){
    super(props)
    this.state={
      userlist:[]
    }
  }
  componentDidMount() {
    //let params = new URLSearchParams(location.id);
    const { match: { params } } = this.props;
    this.setState({
        id: params.reportid
    }, () => console.log('reportid',this.state.id));

}
  render() {
    if(!this.props.isLogin) return <Redirect to='/login' />
    return (
      <Container fluid   >
        <h1>  Şikayetler  </h1>
        <p>
            Şikayet Detay
        </p>
      </Container>
    )
  }
}


const mapStateToProps = state =>{

  return{
    isLogin:state.authReducer.isLogin
  }
}
export default connect(mapStateToProps)(ReportDetails);