
import React, { Component } from 'react'
import { Container, Button,  Form, Grid, Segment, Header, Divider } from 'semantic-ui-react';
import Axios from 'axios';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Current from '../../extension/current'
const cityJson = require('../../data/city.json');
const districtJson = require('../../data/district.json');
class KurumsalDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {

            LokasyonName: '',
            LokasyonType: 0,
            LokasyonHijyen: '',
            LokasyonDenetleyen: '',
            LokasyonDenetlenmeTarihi: new Date(),
            LokasyonCity: 0,
            LokasyonDistrict: 0,
            LokasyonPostCode: '',
            LokasyonAdress: '',
            LokasyonAlternative: {
                gluten: false,
                diyet: false,
                vejeteryan: false,
                helal: false,
                alkolsuz: false,
                organik: false,
            },
            LokasyonCoordinatte: {
                latitude: '',
                longitude: ''
            },
            district: [{
                value: 0,
                text: 'İlçe Seçiniz'
            }],
            btnDisable: false,
            formLoading: true,
            onFinished: false




        }
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(date) {
     
        
        this.setState({
            LokasyonDenetlenmeTarihi: date
        });
        
    }
    componentDidMount() {
        //let params = new URLSearchParams(location.id);
        const { match: { params } } = this.props;
        this.setState({
            id: params.kurumsalid
        }, () => this.details())

    }
    details = () => {


        Axios.get(`https://bisorye.herokuapp.com/api/admin/kurumsaldetails/${this.state.id}`).then((result) => {

            const {
                LokasyonName,
                LokasyonType,              
                LokasyonCity,
                LokasyonDistrict,
                LokasyonPostCode,
                LokasyonAdress,               
                LokasyonCoordinatte,
                LokasyonAlternative,
                _id
            } = result.data;

            const details = {
                id: _id,
                LokasyonName,
                LokasyonType,
                LokasyonCity: LokasyonCity.toString(),
                LokasyonAdress,
                LokasyonCoordinatte: {
                    latitude: parseFloat(LokasyonCoordinatte.latitude),
                    longitude: parseFloat(LokasyonCoordinatte.longitude)
                },             
                LokasyonDistrict: LokasyonDistrict.toString(),
                LokasyonPostCode,
                LokasyonAlternative,
                formLoading:false
            };
            this.setState(details, () => {
                this.updateDistrict(details.LokasyonCity);
                console.log('this.state',this.state);
            })

        }).catch(err => {
            this.setState({ failed: true })
        })

    }
    updateLocation = async () => {
        this.setState({ btnDisable: true, formLoading: true })
       
        this.setState({ btnDisable: true, formLoading: true })
        const {
            LokasyonName,
            LokasyonType,
            LokasyonHijyen,
            LokasyonDenetleyen,
            LokasyonDenetlenmeTarihi,
            LokasyonCity,
            LokasyonDistrict,
            LokasyonPostCode,
            LokasyonAdress,
            LokasyonAlternative,
            LokasyonCoordinatte,
            id
        } = this.state;
        const updateData = {
            id,
            LokasyonName,
            LokasyonType,
            LokasyonHijyen,
            LokasyonDenetleyen,
            LokasyonDenetlenmeTarihi,
            LokasyonCity,
            LokasyonDistrict,
            LokasyonPostCode,
            LokasyonAdress,
            LokasyonAlternative,
            LokasyonCoordinatte
        };
      
        try {
            if(LokasyonName && LokasyonCoordinatte && LokasyonAdress && LokasyonDistrict && LokasyonCity ){
                await Axios.put(`https://bisorye.herokuapp.com/api/admin/kurumsalupdate/${id}`, updateData);
                this.setState({
                    onFinished: true,
                    formLoading: false,
    
                })
            }else{
                alert('Önemli alanları doldurun')
         
            this.setState({
              formLoading: false,
            })
            }
         
        } catch (error) {
            console.log('resultError', error);
            alert('Hata var')
         
            
        }




    }

    updateDistrict = async () => {
        const value = this.state.LokasyonCity

        let district = [];
        for (const key in districtJson) {
            if (districtJson.hasOwnProperty(key)) {
                const element = districtJson[key];

                if (element.city_value === value) {

                    district.push(element);
                }
            }
        }
        this.setState({ district });
    }

    render() {
       
        if (!this.props.isLogin) return <Redirect to='/login' />
        if (this.state.onFinished) return <Redirect to='/admin/kurumsallist' />
        return (
            <Container fluid>
                <h1> Lokasyon Ekle </h1>
                {
                    this.state.LokasyonCoordinatte.latitude ? ((
                        <Segment>
                            <Current locationMarker={this.state.LokasyonCoordinatte} onChange={(LokasyonCoordinatte) => this.setState({ LokasyonCoordinatte })} />
                        </Segment>
                    )) : null
                }
                <Grid columns={3} divided stretched stackable>

                    <Grid.Row >
                        <Grid.Column >
                            <Segment>
                                <Header> Genel Bilgiler </Header>
                                <Divider />
                                <Form loading={this.state.formLoading} >
                                    <Form.Input icon='address card'
                                        iconPosition='left'
                                        label='Lokasyon İsmi'
                                        placeholder='Lokasyon adı Giriniz..'
                                        onChange={(LokasyonName) => this.setState({ LokasyonName: LokasyonName.target.value })}
                                        value={this.state.LokasyonName}
                                    />
                                    <Form.Dropdown
                                        label='Lokasyon Tipi'
                                        placeholder='Seçiniz'
                                        fluid
                                        selection
                                        options={[{text:'Kategorisiz',value:0},
                                        {text:'Restoran/Cafe',value:1},
                                        {text:'Market',value:2},
                                        {text:'AVM',value:3},
                                        {text:'Gıda Üretim İşletmesi',value:4},
                                        {text:'Yemek Fabrikası(Catering)',value:5},
                                        {text:'Kasap Dükkanı',value:6},]}
                                        onChange={(e, { value }) => this.setState({ LokasyonType: value })}
                                        value={this.state.LokasyonType}
                                    />


                                </Form>
                            </Segment>
                            <Segment>
                                <Header> Denetim Bilgisi </Header>
                                <Divider />
                                <Form loading={this.state.formLoading} >
                                    <Form.Dropdown
                                        label='Hijyen Bilgisi'
                                        placeholder='Seçiniz'
                                        fluid
                                        selection
                                        options={[{ text: 'Deneme1', value: 0 }, { text: 'Deneme2', value: 1 }]}
                                        onChange={(e, { value }) => this.setState({ LokasyonHijyen: value })}
                                        value={this.state.LokasyonHijyen}
                                    />
                                    <Form.Input icon='bullseye'
                                        iconPosition='left'
                                        label='Denetleyen Firma'
                                        placeholder='Ad-Soyad Giriniz..'
                                        onChange={(LokasyonDenetleyen) => this.setState({ LokasyonDenetleyen: LokasyonDenetleyen.target.value })}
                                        value={this.state.LokasyonDenetleyen}
                                    />
                                    <Segment>
                                        <Header> Denetlenme Tarihi</Header>
                                        <DatePicker
                                           selected={this.state.LokasyonDenetlenmeTarihi}
                                            onChange={this.handleChange}
                                            dateFormat="dd/MM/yyyy"
                                            selected={null}
                                        />
                                    </Segment>



                                </Form>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column   >
                            <Segment>
                                <Header> Konum Bilgisi </Header>
                                <Divider />
                                <Form loading={this.state.formLoading} >
                                    <Form.Dropdown
                                        label='İl'
                                        fluid
                                        selection
                                        options={cityJson}
                                        onChange={(e, { value }) => this.setState({ LokasyonCity: value }, () => this.updateDistrict())}
                                        defaultValue={"0"}
                                        value={this.state.LokasyonCity}

                                    />
                                    <Form.Dropdown
                                        label='İlçe'
                                        placeholder='Seçiniz'
                                        fluid
                                        selection
                                        options={this.state.district}
                                        onChange={(e, { value }) => this.setState({ LokasyonDistrict: value })}
                                        value={this.state.LokasyonDistrict}
                                    />
                                    <Form.Input icon='point'
                                        iconPosition='left'
                                        label='Posta Kodu'
                                        placeholder='Örn: 34810'
                                        onChange={(LokasyonPostCode) => this.setState({ LokasyonPostCode: LokasyonPostCode.target.value })}
                                        value={this.state.LokasyonPostCode}
                                    />

                                    <Form.TextArea

                                        label='Adress'
                                        placeholder='Örn: Demokrasi Cad No:8 Çekmeköy'
                                        value={this.state.LokasyonAdress}
                                        onChange={(LokasyonAdress) => this.setState({ LokasyonAdress: LokasyonAdress.target.value })}
                                    />




                                </Form>


                            </Segment>

                        </Grid.Column>
                        <Grid.Column  >
                            <Segment>
                                <Header> Alternatif Beslenme Seçenekleri </Header>
                                <Divider />
                                <Form loading={this.state.formLoading} >
                                    <Form.Checkbox label='Glutensiz Yemek Seçeneği'
                                        onChange={() => this.setState(prevState => ({
                                            LokasyonAlternative: {
                                                ...prevState.LokasyonAlternative,
                                                gluten: !this.state.LokasyonAlternative.gluten
                                            }
                                        })
                                        )}
                                        checked={this.state.LokasyonAlternative.gluten} />
                                    <Form.Checkbox label='Diyet Yemek Seçeneği'
                                        onChange={() => this.setState(prevState => ({
                                            LokasyonAlternative: {
                                                ...prevState.LokasyonAlternative,
                                                diyet: !this.state.LokasyonAlternative.diyet
                                            }
                                        })
                                        )}
                                        checked={this.state.LokasyonAlternative.diyet} />
                                    <Form.Checkbox label='Vejeteryan Yemek Seçeneği'
                                        onChange={() => this.setState(prevState => ({
                                            LokasyonAlternative: {
                                                ...prevState.LokasyonAlternative,
                                                vejeteryan: !this.state.LokasyonAlternative.vejeteryan
                                            }
                                        })
                                        )}
                                        checked={this.state.LokasyonAlternative.vejeteryan} />
                                    <Form.Checkbox label='Organik Gıda Seçeneği'
                                        onChange={() => this.setState(prevState => ({
                                            LokasyonAlternative: {
                                                ...prevState.LokasyonAlternative,
                                                organik: !this.state.LokasyonAlternative.organik
                                            }
                                        })
                                        )}
                                        checked={this.state.LokasyonAlternative.organik} />    
                                    <Form.Checkbox label='Helal Et Seçeneği'
                                        onChange={() => this.setState(prevState => ({
                                            LokasyonAlternative: {
                                                ...prevState.LokasyonAlternative,
                                                helal: !this.state.LokasyonAlternative.helal
                                            }
                                        })
                                        )}
                                        checked={this.state.LokasyonAlternative.helal} />
                                    <Form.Checkbox label='Alkolsüz Restoran'
                                        onChange={() => this.setState(prevState => ({
                                            LokasyonAlternative: {
                                                ...prevState.LokasyonAlternative,
                                                alkolsuz: !this.state.LokasyonAlternative.alkolsuz
                                            }
                                        })
                                        )}
                                        checked={this.state.LokasyonAlternative.alkolsuz} />
                                    
                                </Form>
                            </Segment>
                            <Button onClick={() => this.updateLocation()} disabled={this.state.btnDisable} >  Kaydet </Button>

                        </Grid.Column>
                    </Grid.Row>

                </Grid>

            </Container>
        )
    }
}


const mapStateToProps = state => {

    return {
        isLogin: state.authReducer.isLogin
    }
}
export default connect(mapStateToProps)(KurumsalDetails);