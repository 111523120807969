import React,{Component} from 'react'

import { Container, Button, Modal, Table } from 'semantic-ui-react';
import Axios from 'axios';
import {connect} from 'react-redux';
import {Redirect,Link} from 'react-router-dom';
import _ from 'lodash';
let locations = [];
class ReportList extends Component {
  constructor(props){
    super(props)
    this.state={
      userlist:[],
      locations:[],
      open:false
    }
  }
  componentDidMount = async () => {
    const resultuserlist =  await this.getData();
   const locations =  await this.allLocation();
   const userlist = await this.complateUser(resultuserlist,locations);

   this.setState({userlist});
  }
  complateUser=async (resultuserlist) =>{
    let  userlist = [];
   await resultuserlist.map(async (item,index)=>{
      item.LokasyonName = await this.getLocationID(item.locationID);
      userlist.push(item);
    }) 
    return userlist;
  }
  allLocation=async ()=>{
    const url = 'https://bisorye.herokuapp.com/api/admin/alllocation';
    try {
      const result = await Axios.get(url);
     
      
       for (const key in result.data) {
         if (result.data.hasOwnProperty(key)) {
           const element = result.data[key];
          
           locations.push(element);
         }
       }
       //this.setState({locations});
     
       
    } catch (error) {
      console.log('error willmount',error)
    }
  }
  getData=async()=>{
    const url = 'https://bisorye.herokuapp.com/api/admin/allreport';
    try {
      const result = await Axios.get(url);
      //console.log('result',result.data);
       let userlist = [];
       for (const key in result.data) {
         if (result.data.hasOwnProperty(key)) {
           const element = result.data[key];
          
           userlist.push(element);
         }
       }
      // this.setState({userlist});
       return userlist;
       console.log('userlist',userlist);
    } catch (error) {
      console.log('error willmount',error)
    }
  }
  show = (id) => this.setState({id, open: true })
  close = () => this.setState({ open: false,id:undefined })

  onDelete=async()=>{
    try {
        const result =  await Axios.delete(`https://bisorye.herokuapp.com/api/admin/reportdeleted/${this.state.id}`)
        const userlist = _.remove(this.state.userlist,{_id:this.state.id});  
        this.setState({open:false,id:undefined})
     
    } catch (error) {
        console.log('error',error)
    }
}
  getLocationID = async (id) =>{
  
    const result = _.find(locations,{_id:id});
  
    if(result !=undefined){
      return result.LokasyonName;
    }else{
      return 'Silinmiş İşletme'
    }
    
  }
  render() {
    if(!this.props.isLogin) return <Redirect to='/login' />
    return (
      <Container fluid   >
        <h1>  Şikayetler  </h1>
        <Table celled compact definition unstackable>
      <Table.Header fullWidth>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell>UserID</Table.HeaderCell>
          <Table.HeaderCell>LocationID</Table.HeaderCell>   
          <Table.HeaderCell>Message</Table.HeaderCell>     
          <Table.HeaderCell>İşlemler</Table.HeaderCell>     
        </Table.Row>
      </Table.Header>
  
      <Table.Body>
      {this.state.userlist ?  this.state.userlist.map((value,index)=>{
           return(
            <Table.Row key={value._id} >
            <Table.Cell collapsing>
              <Button size='tiny' onClick={()=>this.show(value._id)} >Sil</Button>
            </Table.Cell>
            
            <Table.Cell>{value.userID}</Table.Cell>
            <Table.Cell>{value.LokasyonName}</Table.Cell>
            <Table.Cell>{value.message}</Table.Cell>
            <Table.Cell><Link to={`/admin/reportdetails/${value._id}`}  > <Button  >Detaylar</Button></Link></Table.Cell>
          </Table.Row>
           )
         }) : null}
        
  
      </Table.Body>
  
      <Table.Footer fullWidth>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell colSpan='5'>
          
            <Button size='small'>Sil</Button>
            <Button disabled size='small'>
              Hepsini Sil
            </Button>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
    <Modal size='small' open={this.state.open} onClose={this.close}>
          <Modal.Header>Silme İşlemi Onayı</Modal.Header>
          <Modal.Content>
            <p>Silmek istediğinize emin misiniz?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button negative icon='x' labelPosition='right' content='Hayır' onClick={()=>this.setState({open:false,id:undefined})} />
            <Button positive icon='checkmark' labelPosition='right' content='Evet'  onClick={()=>this.onDelete()} /> 
          </Modal.Actions>
        </Modal>
      </Container>
    )
  }
}


const mapStateToProps = state =>{

  return{
    isLogin:state.authReducer.isLogin
  }
}
export default connect(mapStateToProps)(ReportList);