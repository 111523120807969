import React, { Component } from 'react'

import { Container, Header, Grid, Image,Segment } from 'semantic-ui-react';
import Axios from 'axios';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
class UserDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userlist: []
        }
    }
    componentDidMount = () => {
        const { match: { params } } = this.props;

        this.setState({
            userid: params.userid
        }, () => this.details())


    }

    details = () => {

        Axios.get(`https://bisorye.herokuapp.com/api/admin/memberdetails/${this.state.userid}`).then((result) => {
            console.log('data', result.data);
            this.setState({
                image: result.data.image,
                fullname: result.data.full_name,
                email: result.data.email,
                created_time: result.data.created_time,
                gender: result.data.gender,
                city: result.data.city,
                birthday:result.data.birthday
            })

        }).catch(err => {
            this.setState({ failed: true })
        })
    }


    dateRender = (date) => {
        const newDate = new Date(date);
        return newDate.toLocaleDateString();
    }

    render() {
        if (!this.props.isLogin) return <Redirect to='/login' />
        return (

            <Container  >
                <Header as='h1'>Kullanıcılar</Header>
                <Segment   >   
                <Grid   stackable  >
                
                   <Grid.Column width={4} stackable>
                    <Image src={this.state.image} />
                    </Grid.Column>
                    <Grid.Column width={6} stackable>
                         <p> <b>Adı - Soyadı</b> </p>
                        <p>  {this.state.fullname}   </p>
                        <p> <b>Mail Adresi</b> </p>
                        <p>  {this.state.email}   </p>
                        <p> <b>Cinsiyet</b> </p>
                        <p>  {this.state.gender==='man' ? 'Erkek' :  'Kadın'}   </p>
                    </Grid.Column>
                    <Grid.Column width={6} stackable>
                         <p> <b>Üyelik Tarihi</b> </p>
                        <p>  {this.dateRender(this.state.created_time)}   </p>
                        <p> <b>Doğum Tarihi</b> </p>
                        <p>  {this.state.birthday ? this.state.birthday : 'undefined'}   </p>
                        <p> <b>Şehir</b> </p>
                        <p>  {this.state.city}   </p>
                    </Grid.Column>
                
                    
                    
                </Grid>
                </Segment> 




            </Container>
        )
    }
}

const mapStateToProps = state => {

    return {
        isLogin: state.authReducer.isLogin
    }
}
export default connect(mapStateToProps)(UserDetails);