import React, { Component } from 'react'
import { Container, Header, Segment } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

class HomePage extends Component {
  render() {

    if (!this.props.isLogin) return <Redirect to='/login' />
    return (
  
        <Container fluid   >
          <Segment>
            <Header> Anasayfa </Header>
            <p> Bi'sor ye yönetim paneli üzerinden yapabileceğiniz işlemlere menü üzerinden ulaşabilirsiniz. </p>
          </Segment>

        </Container>
 

    )
  }
}
const mapStateToProps = state => {

  return {
    isLogin: state.authReducer.isLogin
  }
}
export default connect(mapStateToProps)(HomePage);