import React, { Component } from 'react'
import { Container } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

class SettingsPage extends Component {
  render() {

    if (!this.props.isLogin) return <Redirect to='/login' />
    return (
      <Container fluid   >
        <h1>  Mobil Uygulama Ayarları  </h1>
        <p>
           Mobil uygulama Ayarları
        </p>

      </Container>
    )
  }
}
const mapStateToProps = state => {

  return {
    isLogin: state.authReducer.isLogin
  }
}
export default connect(mapStateToProps)(SettingsPage);