import React, { Component } from 'react'
import { Menu } from 'semantic-ui-react'
import TextIcon from "../extension/TextIcon";
import { Link } from 'react-router-dom'
import {connect} from 'react-redux';
class SideMenu extends Component {
    state = {
        activeItem: 'dashboard',
 
        popup:false
    };
    handleItemClick = (e, { name }) => this.setState({ activeItem: name });
    getMenu() {
        const { activeItem } = this.state;
        return (
            <Menu fixed='left' borderless className={(this.props.smallMenu ? 'small-side' : '') + ' side'}  secondary vertical>
                <Menu.Item
                    as={Link}
                    to={'/admin'}
                    name='dashboard'
                    active={activeItem === 'dashboard'}
                    onClick={this.handleItemClick}
                >
             
                    <TextIcon hideText={this.props.smallMenu} color='teal' name='home'>
                        Anasayfa
                    </TextIcon>
                </Menu.Item>

                <Menu.Item name='users'
                    active={activeItem === 'users'}
                    as={Link}
                    to={'/admin/userlist'}
                    onClick={this.handleItemClick}
                >
          
                    <TextIcon hideText={this.props.smallMenu} name='users'>
                        Kullanıcılar
                    </TextIcon>
                </Menu.Item>
                <Menu.Item name='admin'
                    active={activeItem === 'admin'}
                    as={Link}
                    to={'/admin/adminlist'}
                    onClick={this.handleItemClick}
                >
          
                    <TextIcon hideText={this.props.smallMenu} name='users'>
                        Yöneticiler
                    </TextIcon>
                </Menu.Item>
                <Menu.Item

                    name='locations'
                    active={activeItem === 'locations'}
                    as={Link}
                    to={'/admin/locationlist'}
                    onClick={this.handleItemClick}

                >
           
                    <TextIcon hideText={this.props.smallMenu} name='location arrow'>
                        Noktalar
                    </TextIcon>
                </Menu.Item>

                <Menu.Item
                    as={Link}
                    to={'/admin/kurumsal'}
                    name='suitcase'
                    active={activeItem === 'suitcase'}
                    onClick={this.handleItemClick}
                >
             
                    <TextIcon hideText={this.props.smallMenu} name='suitcase'>
                        Kurumsal
                    </TextIcon>

                </Menu.Item>


                <Menu.Item
                    as={Link}
                    to={'/admin/settings'}
                    name='settings'
                    active={activeItem === 'settings'}
                    onClick={this.handleItemClick}
                >
             
                    <TextIcon hideText={this.props.smallMenu} name='setting'>
                        Mobil APP Ayarları
                    </TextIcon>

                </Menu.Item>
                <Menu.Item
                    as={Link}
                    to={'/admin/report'}
                    name='sikayet'
                    active={activeItem === 'sikayet'}
                    onClick={this.handleItemClick}
                >
             
                    <TextIcon hideText={this.props.smallMenu} name='flag'>
                        Şikayetler
                    </TextIcon>

                </Menu.Item>
              
             
                <Menu.Item
                    as={Link}
                    to={'/admin/refferance'}
                    name='refferance'
                    active={activeItem === 'refferance'}
                    onClick={this.handleItemClick}
                >
         
                    <TextIcon hideText={this.props.smallMenu} name='comments'>
                     Üyelik Başvuruları
                    </TextIcon>

                </Menu.Item>
               
                <Menu.Item
                    as={Link}
                    to={'/admin/suggest'}
                    name='suggest'
                    active={activeItem === 'suggest'}
                    onClick={this.handleItemClick}
                >
         
                    <TextIcon hideText={this.props.smallMenu} name='suitcase'>
                        İşletme Önerileri
                    </TextIcon>

                </Menu.Item>
                <Menu.Item
                    as={Link}
                    to={'/admin/supervision'}
                    name='supervision'
                    active={activeItem === 'supervision'}
                    onClick={this.handleItemClick}
                >
         
                    <TextIcon hideText={this.props.smallMenu} name='thumbtack'>
                        Denetim Başvuru
                    </TextIcon>

                </Menu.Item>
                <Menu.Item
                    as={Link}
                    to={'/admin/hygienetraining'}
                    name='hygienetraining'
                    active={activeItem === 'hygienetraining'}
                    onClick={this.handleItemClick}
                >
         
                    <TextIcon hideText={this.props.smallMenu} name='thumbtack'>
                        Hijyen Eğitimi Başvuru
                    </TextIcon>

                </Menu.Item>
                <Menu.Item
                    as={Link}
                    to={'/admin/foodanalyzer'}
                    name='foodanalyzer'
                    active={activeItem === 'foodanalyzer'}
                    onClick={this.handleItemClick}
                >
         
                    <TextIcon hideText={this.props.smallMenu} name='thumbtack'>
                        Gıda Analiz Başvuru
                    </TextIcon>

                </Menu.Item>
          
            </Menu>
        )
    }

    render() {
        return (
            <div className='parent'>
                <div className={(this.props.smallMenu ? 'small-side' : '') + ' side'}>
                    {this.getMenu()}
                </div>
                <div className={(this.props.smallMenu ? 'small-content ' : '') + 'content'}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}
const mapStateToProps = state=>{
    return{
       
        smallMenu: state.settingsReducer.toggle
    }
}
export default connect(mapStateToProps)(SideMenu);
