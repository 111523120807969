export const SETTINGS_TOGGLE='SETTINGS_TOGGLE';

export function settings_toggle(){

	return{
        type:SETTINGS_TOGGLE,
        payload:{
           
        }
    }
}